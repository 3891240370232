<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <template v-if="sent">
      <slot name="sent">
        <div :class="actionColor" class="rounded-md p-4">
          <p class="text-white text-center">
            Thank you, your message has been sent. We'll be in touch shortly.
          </p>
        </div>
      </slot>
    </template>
    <div class="grid grid-cols-1 gap-y-6" role="form" v-if="!sent">
      <div>
        <label for="name" :class="textColor" class="block text-sm font-medium leading-5">Name</label>
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="name" id="name" :class="inputClasses(errors[0], '')" type="text">
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
        <label for="email" :class="textColor" class="block text-sm font-medium leading-5">Email</label>
        <ValidationProvider name="email" rules="required|email" :debounce="500" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="email" id="email" :class="inputClasses(errors[0], '')" type="email">
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
        <label for="message" :class="textColor" class="block text-sm font-medium leading-5">Message</label>
        <ValidationProvider name="message" rules="required" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <textarea v-model="message" id="message" rows="4"
                      :class="inputClasses(errors[0], 'form-textarea')"></textarea>
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button
                @click.prevent="handleSubmit(postForm)"
                type="submit"
                :style="btnActionStyles"
                :class="actionColor"
                class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150">
              Send
              <spinner addtl-class="text-white ml-2" v-if="loading"></spinner>
            </button>
          </span>
      </div>
      <div class="text-sm text-red-600" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorIcon from './icons/error-icon.vue';
import Spinner from './icons/spinner.vue';

export default {
  name: 'tailwind-contact-form',
  components: {
    Spinner,
    ErrorIcon,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    textColor: {
      type: String,
      default: 'text-gray-700',
    },
    focusColor: {
      type: String,
      default: 'dam-orange',
    },
    actionColor: {
      type: String,
      default: 'bg-dam-orange'
    },
    actionStyle: {
      type: String,
      default: 'inherit',
    }
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',

      loading: false,
      sent: false,
      errorMessage: '',
    };
  },
  computed: {
    btnActionStyles() {
      if (this.actionStyle !== 'inherit') {
        return { 'background-color': this.actionStyle }
      }

      return {};
    }
  },
  methods: {
    inputClasses(error, formClass) {
      const normalClasses = `${formClass} py-3 px-4 block w-full transition ease-in-out duration-150 border-gray-300 rounded-md focus:ring-${this.focusColor} focus:border-${this.focusColor}`;
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    postForm() {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      vm.loading = true;
      vm.errorMessage = '';
      const params = {
        inquiry: {
          name: vm.name,
          email: vm.email,
          message: vm.message,
        },
      };
      const url = '/api/team/v1/inquiries';
      axios.post(url, params)
        .then((response) => {
          vm.sent = true;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error: ${error.toString()}`;
        });
    },
  },
};
</script>
