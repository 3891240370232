<template>
  <div>
  </div>
</template>
<script>
import swal from 'sweetalert2';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const vm = this;
    const width = '32em';
    swal.fire({
      title: `${vm.title}`,
      text: vm.text,
      width,
      showCancelButton: false,
      confirmButtonClass: 'inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-300',
      confirmButtonText: "Ok",
      buttonsStyling: false,
    });
  }
};
</script>
<style scoped>
</style>
