<template>
  <div class="ibox">
    <div class="ibox-content p-t-m">
      <div class="row">
        <div class="col-xs-12">
          <div class="cal-container">
            <div v-if="anyLoading">
              <slot name="spinner">
                <public-spinner></public-spinner>
              </slot>
            </div>
            <div ref="calendar">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PublicSpinner from './public_spinner.vue';

export default {
  name: 'public-calendar',
  components: {
    PublicSpinner,
  },
  props: {
    eventRenderCallback: {
      type: Function,
      required: true,
    },
    timeViews: {
      type: String,
      default: 'month,agendaWeek,agendaDay,listMonth',
    },
    defaultMobileView: {
      type: String,
      default: 'listMonth',
    }
  },
  computed: {
    anyLoading() {
      const vm = this;
      return vm.dualMeetLoading || vm.otherLoading || vm.practiceLoading || vm.tournamentLoading;
    },
  },
  data() {
    const vm = this;
    return {

      cal: null,
      error: false,
      errorMessage: '',

      dualMeetLoading: false,
      dualMeetVisible: true,
      dualMeetSource: {
        id: 'dual_meet',
        events(start, end, timezone, callback) {
          const params = `?event_type[]=dual_meet&event_type[]=scramble&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date().toISOString()}`;
          const url = vm.getUrlWithParams(params);
          vm.dualMeetLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
            .then((events) => {
              const fcEvents = vm.formatFCEvents(events);
              callback(fcEvents);
              vm.dualMeetLoading = false;
            })
            .catch((error) => {
              vm.dualMeetLoading = false;
              vm.error = true;
              vm.errorMessage = error.toString();
            });
        },
      },

      otherLoading: false,
      otherVisible: true,
      otherSource: {
        id: 'other',
        events(start, end, timezone, callback) {
          const params = `?event_type=other&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date().toISOString()}`;
          const url = vm.getUrlWithParams(params);
          vm.otherLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
            .then((events) => {
              const fcEvents = vm.formatFCEvents(events);
              callback(fcEvents);
              vm.otherLoading = false;
            })
            .catch((error) => {
              vm.otherLoading = false;
              vm.error = true;
              vm.errorMessage = error.toString();
            });
        },
      },

      practiceLoading: false,
      practiceVisible: true,
      practiceSource: {
        id: 'practice',
        events(start, end, timezone, callback) {
          const params = `?event_type=practice&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date().toISOString()}`;
          const url = vm.getUrlWithParams(params);
          vm.practiceLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
            .then((events) => {
              const fcEvents = vm.formatFCEvents(events);
              callback(fcEvents);
              vm.practiceLoading = false;
            })
            .catch((error) => {
              vm.practiceLoading = false;
              vm.error = true;
              vm.errorMessage = error.toString();
            });
        },
      },

      tournamentLoading: false,
      tournamentVisible: true,
      tournamentSource: {
        id: 'tournament',
        events(start, end, timezone, callback) {
          const params = `?event_type=tournament&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date().toISOString()}`;
          const url = vm.getUrlWithParams(params);
          vm.tournamentLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
            .then((events) => {
              const fcEvents = vm.formatFCEvents(events);
              callback(fcEvents);
              vm.tournamentLoading = false;
            })
            .catch((error) => {
              vm.tournamentLoading = false;
              vm.error = true;
              vm.errorMessage = error.toString();
            });
        },
      },
    };
  },
  mounted() {
    const vm = this;

    // Ensure popovers dismiss on next click
    $(document).on('click', (e) => {
      $('[data-toggle="popover"],[data-original-title]').each(function (el) {
        // the 'is' for buttons that trigger popups
        // the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false; // fix for BS 3.3.6
        }
      });
    });

    // Calendar
    vm.cal = $(vm.$refs.calendar);
    let eventLimit = 3;

    let defaultView = 'month';
    if ($(window).width() < 500) {
      defaultView = this.defaultMobileView;
      eventLimit = 1;
    }

    const args = {
      header: {
        left: 'prev,next',
        center: 'title',
        right: this.timeViews,
      },
      dragRevertDuration: 250,
      longPressDelay: 500,
      navLinks: true,
      editable: false,
      selectable: false,
      selectHelper: true,
      timezone: 'local',
      contentHeight: 'auto',
      defaultView,
      minTime: '07:00:00',
      maxTime: '23:00:00',
      scrollTime: '06:00:00',
      eventRender(eventObj, $el) {
        vm.eventRenderCallback(eventObj, $el);
      },
      eventSources: [
        vm.dualMeetSource,
        vm.otherSource,
        vm.practiceSource,
        vm.tournamentSource,
      ],
      eventLimit: true,
      views: {
        month: {
          eventLimit,
        },
      },
      height: 'auto',
    };

    vm.cal.fullCalendar(args);
  },
  methods: {
    getUrlWithParams(params) {
      return `/api/team/v1/events${params}`;
    },
    formatFCEvents(events) {
      const fcEvents = [];
      events.forEach((returnedEvent) => {
        let start = returnedEvent.start_at;
        let end = returnedEvent.end_at;

        const allDay = returnedEvent.all_day;

        // If it is an all day event, the time becomes 'ambigious' meaning that we only deal with dates.
        if (allDay) {
          start = returnedEvent.start_at.split('T')[0];
          end = returnedEvent.end_at.split('T')[0];
        }

        const { color } = returnedEvent;

        const recurring = returnedEvent.has_repeated_events || returnedEvent.parent_id != null;

        const fcEvent = {
          id: returnedEvent.id,
          title: returnedEvent.name,
          allDay,
          start,
          end,
          color,
          recurring,
          location: returnedEvent.location,
          notes: returnedEvent.notes,
          event_type: returnedEvent.event_type,
          paid_session: returnedEvent.paid_session,
        };
        fcEvents.push(fcEvent);
      });
      return fcEvents;
    },
  },
};
</script>
