<template>
    <div class="sk-spinner sk-spinner-fading-circle" :style="getStyles()">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
    </div>
</template>

<script>
export default {
  name: 'public-spinner',
  props: [
    'inline',
    'ml',
  ],
  methods: {
    getStyles() {
      if (this.inline) {
        if (this.ml) {
          return `display: inline-block; width: 16px; height: 16px; margin-left: ${this.ml}px;`;
        }
        return 'display: inline-block; width: 10px; height: 10px;';
      }
    },
  },
};
</script>
