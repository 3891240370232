export const calendarHelpers = {
  methods: {
    eventDayTime(eventObj) {
      // Handle all day event
      if (eventObj.allDay) {
        return 'All day';
      }

      const start = moment(eventObj.start);
      const end = moment(eventObj.end);
      return `${start.format('hh:mm a')} to ${end.format('hh:mm a')}`;
    },
  },
};
