<template>
  <div>
    <div v-if="loading">
      <spinner></spinner>
    </div>
    <small v-if="error"><span class="text-red-600">{{ errorMessage }}</span></small>
    <div v-if="!loading">
      <div v-for="item in currentPageResults" v-bind:key="item.id">
        <slot name="item"
              :item="item">
        </slot>
      </div>
    </div>
    <nav aria-label="List navigation" class="mt-4 px-4 flex items-center justify-between sm:px-0" v-if="list.length > 1">
      <div class="-mt-px w-0 flex-1 flex">
        <a @click.prevent="showPrev()" aria-label="Previous" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
          <!-- Heroicon name: solid/arrow-narrow-left -->
          <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
          Previous
        </a>
      </div>
      <div class="hidden md:-mt-px md:flex">
        <a :aria-selected="isCurrent(page)" @click.prevent="showPage(page)" v-for="page in pages" :class="pageClasses(page)">
          {{ page }}
        </a>
      </div>
      <div class="-mt-px w-0 flex-1 flex justify-end">
        <a @click.prevent="showNext()" aria-label="Next" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
          Next
          <!-- Heroicon name: solid/arrow-narrow-right -->
          <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </a>
      </div>
    </nav>
    <div v-else-if="list.length === 0 && !loading">
      <slot name="empty">
        <small>No items found</small>
      </slot>
    </div>
  </div>
</template>
<script>
import Spinner from './icons/spinner.vue';
export default {
  name: 'tailwind-paginated-list',
  components: { Spinner },
  props: {
    pageSize: {
      type: Number,
      default: 1,
    },
    url: {
      type: String,
      required: true,
    },
    jsonKey: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      currentPage: 0,
      list: [],
      loading: true,
      error: false,
      errorMessage: '',
    };
  },
  computed: {
    pages() {
      if (this.list.length < this.pageSize) {
        return 1;
      }

      return Math.ceil(this.list.length / this.pageSize);
    },
    currentPageResults() {
      return this.list.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
    },
  },
  mounted() {
    const vm = this;
    vm.loading = true;
    vm.$apiService.loadAllPages(this.url, this.jsonKey)
        .then((items) => {
          vm.list = items;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving items ${error.toString()}`;
          vm.error = true;
        });
  },
  methods: {
    isCurrent(page) {
      return this.currentPage + 1 === page;
    },
    pageClasses(page) {
      if (this.isCurrent(page)) {
        // Current
        return "border-blue-500 text-blue-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium";
      }

      return "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium";
    },
    showPrev() {
      // currentPage is is 0 indexed, but the showPage function auto subtracts 1 (hence why we do it this way)
      this.showPage(this.currentPage)
    },
    showNext() {
      // currentPage is is 0 indexed, but the showPage function auto subtracts 1 (hence why we do it this way)
      this.showPage(this.currentPage + 2)
    },
    // This method handles indexing to 0
    showPage(page) {
      // Handle going under the page size
      if (page <= 0) {
        this.currentPage = this.pages - 1;
        return;
      }
      // Handle going over the page size
      if (page > this.pages) {
        this.currentPage = 0;
        return;
      }
      this.currentPage = page - 1;
    },
  },
};
</script>
