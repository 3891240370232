/*
Deprecated - use billing partner mix instead
 */
export const stripeMix = {
  data() {
    return {
      stripeAcctId: null,
      stripePlatformKey: null,
      stripe: null,
      intentSecret: null,
    };
  },
  methods: {
    setupStripe() {
      const vm = this;
      const url = `/api/team/v1/account/stripe/${vm.apiKey}`;
      axios.get(url)
        .then((response) => {
          vm.stripeAcctId = response.data.stripe.acct_id;
          vm.stripePlatformKey = response.data.stripe.platform_key;
          // todo this should probably be loaded via import statement rather than globally available
          // will need for embedding purposes
          vm.stripe = Stripe(vm.stripePlatformKey, {
            stripeAccount: vm.stripeAcctId,
          });
          vm.loading = false;
        })
        .catch((error) => {
          console.error('Unable to get Stripe info for team');
          console.error(error);
        });
    },
    setupStripeElements(intent, primaryColor, textColor) {
      const vm = this;
      vm.intentSecret = intent.client_secret;
      const options = {
        clientSecret: vm.intentSecret,
        fonts: [{
          cssSrc: 'https://rsms.me/inter/inter.css'
        }],
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: primaryColor,
            colorText: textColor,
            colorDanger: "#BB2525",
            fontFamily: "Inter var, Open Sans, system-ui, sans-serif",
            fontWeightNormal: "600"
          },
        },
      };
      vm.elements = vm.stripe.elements(options);

      // Mount the form
      const paymentElement = vm.elements.create("payment", {
        business: {
          name: vm.teamName,
        },
        terms: {
          card: "never",
        },
      });
      paymentElement.mount("#payment-element");
    },
    async getStripeToken() {
      const vm = this;

      if (vm.creatingToken) {
        return;
      }

      vm.clearError();
      vm.creatingToken = true;
      const elements = vm.elements;

      const { error } = await vm.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        vm.errorMessage = error.message;
        vm.error = true;
        vm.creatingToken = false;
      } else {
        // Usually the promise resolves straight to the return_url, but because of our redirect_if flag,
        // we handle success inline here.
        // Now we need to retrieve the intent to pass it into the endpoint so we know which payment method to charge
        // For some reason this api call is not working with async/await so doing old school calls
        vm.stripe.retrieveSetupIntent(vm.intentSecret).then(function (result) {
          vm.creatingToken = false;
          if (result.error) {
            vm.showError(result.error.message);
          } else {
            vm.tokenized(result.setupIntent.payment_method);
          }
        });
      }
    },
  },
};
