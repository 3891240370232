<template>
  <public-calendar :time-views="timeViews" :default-mobile-view="defaultMobileView" :event-render-callback="eventRender">
    <template v-slot:spinner>
      <spinner :addtl-class="spinnerColor" top="7px" left="90px;"></spinner>
    </template>
  </public-calendar>
</template>
<script>
import tippy from 'tippy.js';
import PublicCalendar from '../sharedteamwebsite/public_calendar.vue';
import Spinner from './icons/spinner.vue';
import { calendarHelpers } from '../sharedteamwebsite/mixins/calendar_helpers';
import './styles/fullcalendar.scss';

export default {
  name: 'tailwind-calendar',
  components: { Spinner, PublicCalendar },
  mixins: [calendarHelpers],
  props: {
    spinnerColor: {
      type: String,
      default: "text-dam-orange",
    },
    timeViews: {
      type: String,
      default: 'month,agendaWeek,agendaDay,listMonth',
    },
    defaultMobileView: {
      type: String,
      default: 'listMonth',
    }
  },
  methods: {
    eventRender(eventObj, $el) {
      tippy($el[0], {
        appendTo: () => document.body,
        content: this.popoverHTML(eventObj),
        allowHTML: true,
        trigger: 'click',
        interactive: true,
        theme: 'light-border',
        popperOptions: {
          strategy: 'fixed',
        },
      });
    },
    popoverHTML(eventObj) {
      const title = `<h1 class="font-medium">${eventObj.title}</h1>`;
      const time = `<p class="mt-2">${this.eventDayTime(eventObj)}</p>`;
      const notes = eventObj.notes ? `<p class="mt-1">${eventObj.notes}</p>` : '';
      const location = eventObj.location ? `<p class="mt-1">${this.urlify(eventObj.location)}</p>` : '';

      let info = '';
      if (notes || location) {
        info = `<div class="mt-4">${location}${notes}</div>`;
      }

      let register = eventObj.paid_session?.registration_url ? `<p class="mt-2 text-sm"><a class="underline flex items-end gap-x-1" href="${eventObj.paid_session.registration_url}">Register for ${eventObj.paid_session.name}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4"><path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" /><path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" /></svg></a></p>` : '';
      return `<div class="px-5 py-3">${title}${time}${info}${register}</div>`;
    },
    urlify(text) {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
      })
    }
  },
};
</script>
