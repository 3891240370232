<template>
  <div>
    <div id="donor-box" class="bg-white">
      <div class="header" :style="primaryColor ? `background-color: ${primaryColor};` : ''">
        <h2 :style="primaryColor ? 'color: white;' : ''">
          Choose an amount to give <span v-if="teamName">to {{ teamName }}</span>
        </h2>
      </div>
      <div class="body">
        <div class="interval-container">
          <div class="btn-group btn-group-justified" role="group" aria-label="...">
            <a v-for="interval in intervals" :class="intervalClass(interval)" :style="intervalStyle(interval)"
               @click="intervalSelected(interval)">{{ interval.cta }}</a>
          </div>
        </div>
        <div class="btn-container">
          <template v-if="tiers.length > 0">
            <div v-for="tier in availableTiers" v-bind:key="tier.id" :class="classesFor(tier)" :style="stylesFor(tier)"
                 @click="tierSelected(tier)">
              {{ ctaFor(tier) }}
            </div>
            <div :class="classesFor(otherTier)" @click="tierSelected(otherTier)">
              <template v-if="otherTier.selected">
                <money ref="money" v-bind="money" v-bind:value="otherTier.amount / 100"
                       @input="otherTier.amount = $event * 100"
                       class="form-input" :style="stylesForInput()"></money>
              </template>
              <template v-else>
                {{ ctaFor(otherTier) }}
              </template>
            </div>
          </template>
          <template v-else>
            <div class="spin-container">
              <slot name="spinner">
                <public-spinner></public-spinner>
              </slot>
            </div>
          </template>
        </div>
      </div>
      <div class="footer">
        <recaptcha
            ref="recaptcha"
            :captcha-action="captchaAction"
            :captcha-site-key="captchaSiteKey"
            :captcha-v3-site-key="captchaV3SiteKey"
            style="margin-bottom: 16px;"
        ></recaptcha>
        <div v-if="useStripe">
          <button type="button"
                  class="btn btn-block btn-primary" @click="createCheckoutSession" :style="stylesForCta()">
            {{ btnCta }}
            <span v-show="loading">
                      <slot name="submitting">
                        <public-spinner :inline="true" ml="16"></public-spinner>
                      </slot>
                    </span>
          </button>
          <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
          <div id="donor-box-accessory">
            <div class="lock-icon">
              <svg viewBox="0 0 20 20" fill="currentColor" class="icon">
                <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <p id="accessory-text">
              You will be redirected to Stripe for secure payment processing. <a target="_blank"
                                                                                 href="https://www.wrestlingiq.com?ref=donor-box">Powered
              by WrestlingIQ</a>.
            </p>
          </div>
        </div>
        <div v-else>
          <button type="button"
                  class="btn btn-block btn-primary" @click="createCheckoutSession" :style="stylesForCta()">
            {{ btnCta }}
            <span v-show="loading">
                      <slot name="submitting">
                        <public-spinner :inline="true" ml="16"></public-spinner>
                      </slot>
                    </span>
          </button>
          <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
          <div id="donor-box-accessory">
            <div class="lock-icon">
              <svg viewBox="0 0 20 20" fill="currentColor" class="icon">
                <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <p id="accessory-text">
              You will be redirected to Stripe for secure payment processing. <a target="_blank"
                                                                                 href="https://www.wrestlingiq.com?ref=donor-box">Powered
              by WrestlingIQ</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money';
import PublicSpinner from './public_spinner.vue';
import Recaptcha from '../shared/recaptcha.vue';
import { stripeMix } from '../shared/stripe_mix.js';
import { recaptchaMix } from '../shared/recaptcha_mix.js';

export default {
  name: 'donor-box',
  mixins: [
    stripeMix,
    recaptchaMix,
  ],
  components: {
    PublicSpinner,
    Money,
    Recaptcha,
  },
  props: {
    primaryColor: {
      type: String,
    },
    apiKey: {
      type: String,
    },
    billingPartner: {
      type: String,
      required: true
    },
    successUrl: {
      type: String,
    },
    teamName: {
      type: String,
    },
    initialInterval: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
      tiers: [],
      otherTier: {
        id: -1,
        amount: 50000,
        selected: false,
        currency: 'usd',
      },
      intervals: [
        {
          id: 0,
          cta: 'Give once',
          selected: true,
        },
        {
          id: 1,
          cta: 'Monthly',
          selected: false,
        },
      ],
    };
  },
  computed: {
    useStripe() {
      return this.billingPartner === "Stripe";
    },
    // todo clean this magic number shit up
    selectedTier() {
      if (this.otherTier.selected) {
        return this.otherTier;
      }

      const interval = this.selectedInterval.id === 0 ? 'one_time' : 'month';
      return _.head(_.filter(this.tiers, {
        selected: true,
        interval
      }));
    },
    availableTiers() {
      // Filters down the dynamic tiers, other is always present
      const interval = this.selectedInterval.id === 0 ? 'one_time' : 'month';
      return _.filter(this.tiers, ['interval', interval]);
    },
    selectedInterval() {
      return _.head(_.filter(this.intervals, ['selected', true]));
    },
    recurringSelected() {
      return this.selectedInterval.id === 1;
    },
    btnCta() {
      const tier = this.selectedTier;
      if (!tier) {
        return 'Donate';
      }

      const amount = tier.amount / 100;
      const recurringExtra = this.recurringSelected ? '/month' : '';
      return `Donate $${amount} ${recurringExtra}`;
    },
  },
  mounted() {
    this.setupStripe();
    this.getTiers();
    let interval = _.head(_.filter(this.intervals, ['id', this.initialInterval]));
    this.intervalSelected(interval);
  },
  methods: {
    intervalClass(interval) {
      const activeClass = interval.selected ? 'active' : '';
      return `btn interval-btn ${activeClass}`;
    },
    intervalStyle(interval) {
      if (!this.primaryColor) {
        return '';
      }

      if (interval.selected) {
        return `border: 1px solid ${this.primaryColor}; background-color: ${this.primaryColor}`;
      }

      return `border: 1px solid ${this.primaryColor}; color: ${this.primaryColor}`;
    },
    tierIsOther(tier) {
      return tier.id === this.otherTier.id;
    },
    classesFor(tier) {
      const activeClass = tier.selected ? 'active' : '';
      const otherClass = this.tierIsOther(tier) ? 'two-col-btn' : '';
      return `amount-btn ${activeClass} ${otherClass}`;
    },
    stylesFor(tier) {
      if (!this.primaryColor) {
        return '';
      }

      if (tier.selected) {
        return `border: 1px solid ${this.primaryColor}; color: ${this.primaryColor}; box-shadow: inset 0 0 0 1px ${this.primaryColor}`;
      }

      return this.textColor ? `border: 1px solid ${this.textColor}; color: ${this.textColor};` : '';
    },
    stylesForCta() {
      if (this.primaryColor) {
        return `background-color: ${this.primaryColor}; border-color: ${this.primaryColor}`;
      }

      return '';
    },
    stylesForInput() {
      if (this.primaryColor) {
        return `border-color: ${this.primaryColor}`;
      }

      return '';
    },
    ctaFor(tier) {
      if (this.tierIsOther(tier)) {
        return 'Other amount';
      }
      const recurringExtra = (this.recurringSelected && !this.tierIsOther(tier)) ? '/mo' : '';
      const amount = tier.amount / 100;
      return `$${amount} ${recurringExtra}`;
    },
    intervalSelected(interval) {
      this.intervals = _.map(this.intervals, (i) => {
        i.selected = i.id === interval.id;
        return i;
      });
    },
    tierSelected(tier) {
      const otherSelected = this.tierIsOther(tier);
      this.tiers = _.map(this.tiers, (t) => {
        // Ensure only this new tier is selected, but maintain the other interval tier as well
        // With other, we make an exception. Other is the fully active one if it has been chosen
        if (otherSelected) {
          t.selected = false;
        } else if (t.interval === tier.interval) {
          t.selected = t.id === tier.id;
        }
        return t;
      });

      // if we need to focus the other amount element
      if (otherSelected) {
        this.otherTier.selected = true;
        this.$nextTick(function () {
          this.$refs.money.$el.focus();
        });
      } else {
        this.otherTier.selected = false;
      }
    },
    getTiers() {
      const vm = this;
      const url = '/api/team/v1/donations/plans';
      axios.get(url)
          .then((response) => {
            vm.loading = false;
            vm.tiers = _.map(response.data.plans, (plan) => {
              plan.selected = (plan.amount === 5000); // todo make this work better
              return plan;
            });
          })
          .catch((error) => {
            vm.errorMessage = 'Error retrieving available donation amounts';
            vm.error = true;
          });
    },
    createCheckoutSession: _.throttle(async function () {
      const vm = this;
      if (vm.loading) {
        return;
      }
      vm.error = false;

      if (!vm.selectedTier) {
        vm.errorMessage = 'Please select an amount';
        vm.error = true;
        return;
      }

      vm.loading = true;

      // Okay so this is a stripe checkout session specific to donations
      const url = '/api/team/v1/donations/checkouts';
      const interval = vm.otherTier.selected ? vm.selectedInterval.cta : vm.selectedTier.interval;
      const data = {
        'g-recaptcha-response-data': await vm.$refs.recaptcha.getCaptchaResponse(),
        success_url: vm.successUrl ? vm.successUrl : `${window.location.href}/donate/success`,
        cancel_url: window.location.href,
        account_id: vm.stripeAcctId,
        price: {
          id: vm.selectedTier.id,
          amount: vm.selectedTier.amount,
          interval,
        },
      };
      axios.post(url, data)
          .then((response) => {
            vm.loading = false;
            vm.stripe.redirectToCheckout({
              sessionId: response.data.id,
            })
                .then((result) => {
                  vm.errorMessage = result.error.message;
                  vm.error = true;
                });
          })
          .catch((error) => {
            vm.loading = false;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            } else {
              vm.errorMessage = `Error, please try again later ${error.toString()}`;
            }
            vm.error = true;
          });
    }, 500),
  },
};
</script>
