<template>
  <div class="relative" :class="containerClass">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
      <nav class="relative flex items-center justify-between sm:h-10 lg:justify-center">
        <div class="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
          <div class="flex items-center justify-between w-full lg:w-auto">
            <a href="/" aria-label="Home">
              <slot name="desktop-image"></slot>
            </a>
            <div class="-mr-2 flex items-center lg:hidden">
              <button @click="showMobileMenu = true" type="button"
                      :class="`inline-flex items-center justify-center p-2 rounded-md ${color} ${colorHover} ${bg} ${bgHover} focus:outline-none transition duration-150 ease-in-out`"
                      id="main-menu" aria-label="Main menu" aria-haspopup="true">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 6h16M4 12h16M4 18h16"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="hidden lg:flex lg:space-x-10">
          <slot name="desktop"></slot>
        </div>
        <div class="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
          <slot name="desktop-cta"></slot>
        </div>
      </nav>
    </div>
    <!--
      Mobile menu, show/hide based on menu open state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <transition
        enter-active-class="duration-150 ease-out"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
    >
      <div v-show="showMobileMenu" class="z-40 absolute top-0 inset-x-0 p-2 transition origin-top-right lg:hidden">
        <div class="rounded-lg shadow-md">
        <div class="rounded-lg overflow-hidden" :class="mobileBgRing" role="menu" aria-orientation="vertical"
             aria-labelledby="main-menu">
          <div class="px-5 pt-4 flex items-center justify-between">
            <div>
              <slot name="mobile-image"></slot>
            </div>
            <div class="-mr-2">
              <button @click="showMobileMenu = false" type="button"
                      :class="`inline-flex items-center justify-center p-2 rounded-md ${color} ${colorHover} ${bg} ${bgHover} focus:bg-gray-100 focus:text-gray-500 focus:outline-none transition duration-150 ease-in-out`"
                      aria-label="Close menu">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
          </div>
          <slot name="mobile"></slot>
        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'tailwind-nav',
  props: {
    bg: {
      type: String,
      default: 'bg-white'
    },
    bgHover: {
      type: String,
      default: 'hover:bg-gray-100'
    },
    color: {
      type: String,
      default: 'text-gray-400'
    },
    colorHover: {
      type: String,
      default: 'hover:text-gray-500'
    },
    containerClass: {
      type: String,
      default: 'pt-6'
    },
    mobileBgRing: {
      type: String,
      default: 'bg-white ring-1 ring-black ring-opacity-5',
    }
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
};
</script>
