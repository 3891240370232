<template>
  <a
      :href="fundraiserUrl"
      class="mt-4 flex items-center justify-center px-4 py-4 border border-transparent shadow-sm text-base font-medium rounded-md w-full"
      :style="buttonStyles">
    <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"/>
    </svg>
    Contribute Now
  </a>
</template>
<script>
export default {
  name: "fundraiser-cta-button",
  props: {
    fundraiserSlug: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    }
  },
  computed: {
    fundraiserUrl() {
      return `${this.teamSlug}/fundraiser/${this.fundraiserSlug}`;
    },
    buttonStyles() {
      return {
        'background-color': this.bgColor,
        color: this.color,
      }
    }
  }
};
</script>
<style scoped>
</style>
