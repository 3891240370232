<template>
  <div class="relative w-full h-6 rounded-full border mt-1" :style="{ borderColor: this.color }">
    <div class="z-0 absolute h-full rounded-full" :style="barWidthAndColor"></div>
  </div>
</template>
<script>
export default {
  name: 'progress-bar',
  props: {
    color: {
      type: String,
      default: '#1D4ED8'
    },
    completedAmount: {
      type: Number,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    }
  },
  computed: {
    barWidthAndColor() {
      const width = this.completedAmount > this.totalAmount ? 100 : (this.completedAmount / this.totalAmount) * 100;
      return {
        width: `${width}%`,
        backgroundColor: this.color,
      };
    },
  }
};
</script>
