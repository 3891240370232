<template>
  <div class="relative">
    <button type="button"
            @click="showFlyout = !showFlyout"
            :class="[showFlyout ? openTextColor : textColor, bgColor, focusClass]"
            class="group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
            aria-expanded="false">
      <span><slot name="title"></slot></span>
      <svg
          :class="[showFlyout ? 'text-gray-600' : arrowColor]"
          class="ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"/>
      </svg>
    </button>
    <!--
      Flyout menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0 translate-y-1"
        To: "opacity-0 translate-y-1"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100 translate-y-0"
        To: "opacity-0 translate-y-1"
    -->
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="opacity-0 translate-y-1"
        enter-to-class="opacity-0 translate-y-1"
        leave-active-class="transition ease-in duration-150"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
    >
      <div v-show="showFlyout" class="absolute z-10 left-1/2 -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <slot name="items"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'tailwind-flyout',
  props: {
    bgColor: {
      type: String,
      default: 'bg-white',
    },
    textColor: {
      type: String,
      default: 'text-gray-900'
    },
    openTextColor: {
      type: String,
      default: 'text-gray-800'
    },
    arrowColor: {
      type: String,
      default: 'text-gray-600',
    },
    focusClass: {
      type: String,
      default: 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200',
    }
  },
  data() {
    return {
      showFlyout: false,
    }
  },
};
</script>
