<template>
  <div
      class="px-8 grid grid-cols-1 mx-auto justify-items-center space-y-24 relative mt-24 md:mt-32 lg:grid-cols-2 lg:px-8 lg:max-w-7xl">
    <div class="flex items-center">
      <div class="flex flex-col lg:p-16">
        <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
          {{ teamName }}
        </h1>
        <slot name="description">
        </slot>
        <div class="mt-8 md:mt-16 lg:mt-24">
          <nav class="flex space-x-4" aria-label="Tabs">
            <a @click.prevent="selectedTab = 'contact'"
               class="text-white text-center px-3 w-1/3 py-3 font-medium text-sm rounded-md border border-gray-500"
               :class="{'text-white': contactSelected, 'text-gray-500': !contactSelected}"
               :style="contactSelected ? {backgroundColor: brandColor} : {backgroundColor: 'white'}"
               :aria-selected="contactSelected">
              Contact
            </a>
            <a @click.prevent="selectedTab = 'donate'"
               class="text-white text-center px-3 w-1/3 py-3 font-medium text-sm rounded-md border border-gray-500"
               :class="{'text-white': donateSelected, 'text-gray-500': !donateSelected}"
               :style="{ backgroundColor: donateSelected ? brandColor : 'white'}"
               :aria-selected="donateSelected"
            >
              Donate
            </a>
            <a @click.prevent="selectedTab = 'fundraisers'"
               class="text-white text-center px-3 w-1/3 py-3 font-medium text-sm rounded-md border border-gray-500"
               :class="{'text-white': fundraisersSelected, 'text-gray-500': !fundraisersSelected}"
               :style="{ backgroundColor: fundraisersSelected ? brandColor : 'white'}"
               :aria-selected="fundraisersSelected"
            >
              Fundraisers
            </a>
            <a
                v-if="formUrl"
                :href="formUrl"
               class="text-gray-500 text-center px-3 w-1/3 py-3 font-medium text-sm rounded-md border border-gray-500 bg-white">
              Join
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div class="flex w-full md:w-3/4 md:items-center justify-center">
      <div v-show="contactSelected" key="contact">
        <slot name="contact">
        </slot>
      </div>
      <div v-show="donateSelected" key="donate">
        <slot name="donate">
        </slot>
      </div>
      <div v-show="fundraisersSelected" key="fundraisers">
        <slot name="fundraisers">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'booster-tabs',
  props: {
    teamName: {
      type: String,
      required: true
    },
    brandColor: {
      type: String,
      required: true
    },
    initialTab: {
      type: String,
      default: 'donate',
    },
    formUrl: {
      type: String,
      default: '',
    }
  },
  data: function () {
    return {
      selectedTab: 'fundraisers'
    };
  },
  mounted() {
    this.selectedTab = this.initialTab;
  },
  computed: {
    contactSelected() {
      return this.selectedTab === 'contact';
    },
    donateSelected() {
      return this.selectedTab === 'donate';
    },
    fundraisersSelected() {
      return this.selectedTab === 'fundraisers';
    }
  },
  methods: {}
};
</script>
