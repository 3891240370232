import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

export default function () {
  // No message specified.
  extend('email', {
    ...email,
    message: 'Please enter your {_field_}.',
  });

  // Override the default message.
  extend('required', {
    ...required,
    message: 'Please enter your {_field_}.',
  });
}
